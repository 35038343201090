html, body, h1, input, select, pre {font-family:'Noto Sans KR', 'Apple SD Gothic Neo', arial, sans-serif}
pre {margin: 0 0 0 0;}
body, h1 {font-size:14px;}
h1 {font-weight:normal;margin:0;padding:0}
h3 {font-weight:normal;margin:0;padding:0;font-size:20px;line-height:1.3}
body {margin:0;background:#fff;color:#202124;}
a {text-decoration:none;-webkit-tap-highlight-color:rgba(0, 0, 0, .10);color:#000;}
a:hover {text-decoration:none;}
cite, cite a:link, cite a:visited {color:#202124;font-style:normal}
button {margin:0}
ol li {list-style:none}
ol, ul, li {margin:0;padding:0;list-style: none;}
input {font-size:14px}
em {font-weight:bold;font-style:normal}
p {margin: 0;}
* {box-sizing: border-box;}

/* additional */
button {border: 0 none; background: transparent;}